import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as Icons from '@benepass/icons';

export type IconType = keyof typeof Icons;

const isIconType = (icon: string): icon is IconType => {
  return icon in Icons;
};

const getRecurringContainerHeight = (props: Props) => {
  return props.height && `${props.height - props.height / 3}px`;
};

const RecurringContainer = styled.div<Props>`
  position: relative;
  width: ${(props: Props) => props.width && `${props.width}px`};
  height: ${(props: Props) => props.height && `${props.height}px`};
  ::after {
    content: '';
    display: block;
    position: absolute;
    height: ${getRecurringContainerHeight};
    bottom: -8px;
    right: -8px;
    border-radius: 50%;
    padding: 1px;
    width: ${(props: Props) => props.width && `${props.width - props.width / 3}px`};
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
  }
`;
export type Props = React.HTMLAttributes<SVGSVGElement> & {
  name: string;
  isRecurring?: boolean;
  height?: number;
  width?: number;
  size?: number | string;
  margin?: number;
  color?: string;
};

const Icon = ({ name, isRecurring = false, ...props }: Props): JSX.Element => {
  const Component = useMemo(() => {
    const IconTag = Icons[isIconType(name) ? name : 'Question'];
    return <IconTag {...props} />;
  }, [name, props]);

  if (isRecurring) {
    // @FIXME - add type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <RecurringContainer {...(props as any)}>{Component}</RecurringContainer>;
  }

  return Component;
};

export default Icon;
