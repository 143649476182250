import * as RadixAvatar from '@radix-ui/react-avatar';

import { classNames } from 'utils/src/classnames';

import customTwMerge from '../../utils/twMerge';

export type Props = {
  /* Name of the person */
  name?: string;
  /* Image url of the 'Avatar' */
  src?: string;
  /* Loading strategy 'lazy' or 'edger' */
  loading?: 'eager' | 'lazy';
  /* Function to get initials or fallback */
  getInitials?: (name: string) => string;
  /* Avatar size  */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  /** Background color of fallback */
  color?: 'indigo' | 'gray';
  /* Custom className */
  className?: string;
};

const defaultGetInitials = (name: string): string => {
  if (!name) return '';
  const [firstName, lastName] = name.split(' ');
  return firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : firstName?.charAt(0) ?? '';
};

const Avatar = ({
  loading = 'lazy',
  getInitials = defaultGetInitials,
  size = 'sm',
  color = 'indigo',
  ...props
}: Props): JSX.Element | null => {
  if (!props.name && !props.src) return null;

  const sizeClassNames = [
    {
      'w-5 h-5': size === 'xs',
      'w-10 h-10': size === 'sm',
      'w-12 h-12': size === 'md',
      'w-16 h-16': size === 'lg',
      'w-20 h-20': size === 'xl',
    },
  ];

  return (
    <RadixAvatar.Root
      className={classNames([
        'inline-flex items-center rounded-full overflow-hidden',
        props.className ?? '',
        ...sizeClassNames,
      ])}
    >
      <RadixAvatar.Image src={props.src} loading={loading} className="object-cover" />

      <RadixAvatar.Fallback
        className={customTwMerge(
          [
            'text-header-1',
            'text-white w-full h-full flex items-center justify-center uppercase',
            color === 'gray' ? 'bg-grayscale-48' : 'bg-indigo-100',
            size === 'xl' ? 'text-display-4' : '',
            size === 'xs' ? 'text-xs' : '',
          ].join(' ')
        )}
      >
        {props.name ? getInitials(props.name) : ''}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
};

export default Avatar;
